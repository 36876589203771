import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import slugify from "slugify";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import collections1 from "../../assets/image/collections1.webp";
import collections2 from "../../assets/image/collections2.webp";

import ironflip from "../../assets/image/ironflip.webp";
import max from "../../assets/image/max.webp";
import curve from "../../assets/image/curve.webp";
import ring from "../../assets/image/ring.webp";
import watch from "../../assets/image/watch.webp";
import head from "../../assets/image/head.webp";

const Collections = () => {
  const [data, setData] = useState([]);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  useEffect(() => {
    Promise.all([axios.get("https://myshops.az/apk/app_prod.php?q=vertu")])
      .then(([dataRes]) => {
        const data = dataRes.data;
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="container my-5">
        <div className="row justify-content-center g-3">
          <div className="col-12">
            <div className="flat-title mb_1 gap-14">
              <span
                className="title wow fadeInUp text-uppercase fs-3 text-center"
                data-wow-delay="0s"
              >
                {t.collecDesc}
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-12">
            <NavLink to="/ironflip" onClick={() => window.scrollTo({ top: 0 })}>
              <div className="collectionsBox">
                <img src={ironflip} alt="" className="img-fluid" />
                <p>IRON FLIP</p>
                <button style={{width:`${language == "az" ? "auto" : "60%"}`}}>{t.buynow}</button>
              </div>
            </NavLink>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-12">
            <NavLink to="/ring" onClick={() => window.scrollTo({ top: 0 })}>
              <div className="collectionsBox">
                <img src={ring} alt="" className="img-fluid" />
                <p className="text-center">AI Smart Ring</p>
                <button style={{width:`${language == "az" ? "auto" : "60%"}`}}>{t.buynow}</button>
              </div>
            </NavLink>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-12">
            <NavLink to="/watch" onClick={() => window.scrollTo({ top: 0 })}>

            <div className="collectionsBox">
              <img src={watch} alt="" className="img-fluid" />
              <p className="text-center">GRAND WATCH</p>
              <button style={{width:`${language == "az" ? "auto" : "60%"}`}}>{t.buynow}</button>
            </div>
            </NavLink>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-12">
            <NavLink to="/head" onClick={() => window.scrollTo({ top: 0 })}>

            <div className="collectionsBox">
              <img src={head} alt="" className="img-fluid" />
              <p>OWS Headphones</p>
              <button style={{width:`${language == "az" ? "auto" : "60%"}`}}>{t.buynow}</button>
            </div>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collections;
